// 商品分类
import React, { Component } from "react";
import { Layout} from 'antd';

import {getClassifyList,getClassifyCommodity} from '../../api/service';
import {goodsLocationOpen} from '../../config/util';
import "./css/Shop.less";

const { Content } = Layout;

class Shop extends Component{
    constructor(){
        super();
        this.state = {
            selectClassifyId:"", //默认选中的分类id
            selectClassifyName:"", //默认选中的分类名
            initClassifyList:[], //分类列表
            classifyCommodity:[], //选中分类商品列表
        }
    }
    componentDidMount() {
        // console.log("获取路由传递参数",this.props.location.state)
        this.initClassifyList();
    }

    // 初始化分类列表
    initClassifyList = () => {
        getClassifyList().then((res) => {
            // console.log("获取分类列表",res);
            // 设置分类和焦点分类
            this.setState({
                initClassifyList:res,
                selectClassifyId:this.props.location.state || res[0].id
            },() => {
                // 根据加载商品列表
                this.loadClassifyCommdity();
                // // 设置选中分类明
                // this.setState({
                //     selectClassifyName:this.props.location.state || res[0].id
                // })
            })

        })
    }

    //根据加载商品列表
    loadClassifyCommdity = () => {
        // console.log("默认选中的分类",this.state.selectClassifyId);
        if(this.state.selectClassifyId){

            getClassifyCommodity({
                classifyId:this.state.selectClassifyId
            }).then((res) => {
                // 选中的分类名
                this.state.initClassifyList.forEach((item,index) => {
                    if(item.id == this.state.selectClassifyId){
                        this.setState({
                            selectClassifyName:item.cname
                        })                        
                    }
                })
                console.log("选中的分类商品",res);
                // 选中的分类商品
                this.setState({
                    classifyCommodity:res,
                })

            })
        }
    }

    //分类切换商品列表
    //@params selectClassifyId切换的分类id
    changeClassifyComodity = (selectClassifyId) => {
        // console.log("selectClassifyId切换的分类id",selectClassifyId);
        this.setState({
            selectClassifyId:selectClassifyId,
        },() => {
            this.loadClassifyCommdity();
        })        
    }

    goodsClick = (goods) => {
        if(goods.jumpUrl){
          goodsLocationOpen(goods.jumpUrl);
        }else{
          this.props.history.push({
              pathname:"/GoodsDetail/"+goods.id
          })   
        }
    }    

    render() {
        let {selectClassifyId,selectClassifyName,initClassifyList,classifyCommodity} = this.state;
        return (
            <Content >
                <div className="Shop_Box">
                    {/* 头部标题 */}
                    <div className="Shop_titleBox">
                        <div className="title">
                            <span>PRODUCTS  |  </span>
                            <span className="actived">  {selectClassifyName}</span>
                        </div>
                    </div>
                    {/* 商品列表 */}
                    <div className="Shop_ListBox">
                        {/* 分类 */}
                        <div className="classify_box">
                            {
                                initClassifyList.map((item,index) => {
                                    return (
                                        <div key={item.cname} 
                                            onClick={() => {
                                                this.changeClassifyComodity(item.id);
                                            }}
                                        >
                                            <span className={item.id == selectClassifyId ? "actived" : ""}>{item.cname}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/* 商品列表 */}
                        <div className="goods_box">
                            <h2 className="gb_title">{selectClassifyName}</h2>
                            <div className="gb_box">
                                {
                                    classifyCommodity.map((item,index) => {
                                        return (
                                            <div className="si_imgCont" key={item.id}>
                                                <div className="header">
                                                    <img src={item.commodityPictures} alt="图片加载失败" 
                                                    className="goodsImg" />
                                                    <div className="mask">
                                                        <div className="BuyBtn"  
                                                            onClick={() => {
                                                                this.goodsClick(item)
                                                            }} 
                                                        >Buy Now</div>
                                                    </div>
                                                </div>
                                                <div className="title overflow-e2">{item.commodityName}</div>
                                                {item.commodityPrice ? 
                                                    <div className="price overflow-e1">${item.commodityPrice}</div>       
                                                    :
                                                    "--"
                                                }                             
                                            </div>
                                        )
                                    })
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        )
    }
}

export default Shop;