import { message } from 'antd';


/**
 * 节流
 * @param {Function} func
 * @param {Number} wait
 */
 export function throttle(fn, gapTime) {
    if (gapTime === null || gapTime === undefined) {
      gapTime = 1500;
    }
    let _lastTime = null;
    // 返回新的函数
    return function () {
      let _nowTime = +new Date();
      if (_nowTime - _lastTime > gapTime || !_lastTime) {
        fn.apply(this, arguments); //将this和参数传给原函数
        _lastTime = _nowTime;
      }
    };
  }

  /**
 * 商品跳转页面
 * @param {route} 跳转路由
 */
 export function goodsLocationOpen(route = ""){
  if(!route){
    message.warning("The merchandise is sold out");
    return;
  };
  window.open(route);
}