import React from 'react';
// import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import store from './store/store';
import {HashRouter} from 'react-router-dom';
import Router from './router/router';
import { Layout} from 'antd';

import './App.less';
import SMHeader from './pages/SMHeader.jsx';
import SMFooter from './pages/SMFooter.jsx';


function App() {
  return (
    <div className="App">
      <Provider store={store}>
          <HashRouter>
              <Layout>
                <SMHeader></SMHeader>
                <Router></Router>
                <SMFooter></SMFooter>
              </Layout>
          </HashRouter>
      </Provider>
    </div>
  );
}

console.log(process.env);

export default App;
