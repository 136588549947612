// 首页
import React, { Component } from "react";
import { Layout, Carousel} from 'antd';

import {connect} from 'react-redux';
import {updateNav} from '../../store/navSetting/action';

import {getRoetationList,getSysCommodities} from '../../api/service';

// import history from '../../config/history';
import {goodsLocationOpen} from '../../config/util';

import "../public.less";
import "./css/Home.less";

const { Content } = Layout;

class Home extends Component{
    constructor(){
        super();
        this.state = {
            isShowHoverDialog:true,
            roetationList:[], //轮播图列表
            commodityList:[], //商品列表
            ipDesigner:[], //ip列表-默认第一类商品
        }
    }
    componentDidMount() {
        // 轮播图
        getRoetationList().then((res) => {
            this.setState({
                roetationList:res
            })
        })
        // 系统商品
        getSysCommodities().then((res) => {
            this.setState({
                commodityList:res,
                ipDesigner:res[0].list
            })
        })
    }
    /*
        @param route跳转路由的路径
        @param param跳转路由携带的参数
        @param navActiveIndex路由焦点事件
    */
    goRouter = (route,param,navActiveIndex)=>{
        if (!route) return;
        this.props.updateNav({
            navActiveIndex:navActiveIndex
        });
        var path = {
            pathname:route,
            state:param,
        }
        this.props.history.push(path)        
      }

      goodsClick = (goods) => {
          if(goods.jumpUrl){
            goodsLocationOpen(goods.jumpUrl);
          }else{
            this.props.history.push({
                pathname:"/GoodsDetail/"+goods.id
            })   
          }
      }
    render() {
        let {roetationList,commodityList,ipDesigner} = this.state;
        const contentStyle = {
            CarouselImg:{
                height: (window.innerHeight - 74) + 'px',
                maxHeight: (window.innerHeight - 74) + 'px',
            },
            ABVideoImg:{
                height:window.innerHeight,
            }
          };
          
        return (
            <Content className="Cont_Home" >
                {/* 轮播图 */}
                <Carousel autoplay autoplaySpeed={6000} className="SMBanner">
                    {
                        roetationList.map((item) => {
                            return (
                                <div key={item.id}>
                                    <img style={contentStyle.CarouselImg} src={item.picture} alt="图片加载失败" onClick={() => {
                                        this.goodsClick(item)
                                    }} />
                                </div>
                            )
                        })
                    }
                    
                </Carousel>
                {/* 商品展示区 */}
                <div className="Cont_goodsCont">
                    {/* NEW ARRIVAL */}
                    {
                        commodityList.map((item) => {
                            return (
                                <div className="NS_itemCont" key={item.classifyName}>
                                {/* 标题 */}
                                <div className="section-header" >
                                    <h2 className="font-mont-light">{item.classifyName}</h2>
                                </div>  
                                <div className="section-item">
                                    {
                                        item.list.map((goodsItem,goodsIndex) => {
                                            return(
                                                <div className="si_imgCont" key={goodsItem.id}>
                                                    {/* 每个商品 */}
                                                    <div className="header">
                                                        <img src={goodsItem.commodityPictures} alt="图片加载失败" 
                                                        className="goodsImg" />
                                                        <div className="mask">
                                                            <div className="BuyBtn"
                                                                onClick={() => {
                                                                    this.goodsClick(goodsItem)
                                                                }}                                                             
                                                            >Buy Now</div>
                                                        </div>
                                                    </div>
                                                    <div className="title overflow-e2">{goodsItem.commodityName}</div>
                                                    {goodsItem.commodityPrice ? 
                                                        <div className="price overflow-e1">${goodsItem.commodityPrice}</div>
                                                        :
                                                        "--"
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                {/* 分类 */}
                                <div className="viewAll_box" onClick={()=>{
                                    this.goRouter('/Shop',item.classifyId,2)
                                }}>
                                   <div className="item">
                                        VIEW ALL
                                   </div>
                                </div>
                            </div>
                            )
                        })
                    }
                                   

                </div>

                {/* 关于我们-视频区 */}
                <div className="Cont_ABVideo" style={contentStyle.ABVideoImg}>
                    <div className="title">ABOUT MU</div>

                    <div className="desc">Shanghai Art Model Electronic & Technology Co.,Ltd and its newly brand MU are enjoying high reparation and market share in Chinese 3D metal puzzle industries. The company is an Innovative enterprise integrating R&D, design, production and marketing and its history can be traced back to 2000.</div>
                    <div className="desc">2017 is a milestone event year for Shanghai Art Model Co., Ltd,who established the business cooperation with Hasbro and became the only one manufacturer that get the authorization from Hasbro for 3D Transformer metal puzzle production in China.</div>
                    <div className="desc">“Success on hand and craft in heart”is our enterprise motto. Go with us for assembling joy of life!</div>
                </div>

                {/* IP&Designer */}
                <div className="Cont_goodsCont Cont_ipDes">
                    <div className="NS_itemCont idBox">
                        {/* 标题 */}
                        <div className="section-header" >
                            <h2 className="font-mont-light">IP & DESIGNER</h2>
                        </div>   
                        {/* 内容 */}

                        <div className="section-item">
                            {/* 每个商品 */}
                            {
                                ipDesigner.slice(0,5).map((item,index) => {
                                    return (
                                        <div className="ipsi_box" key={item.id}>
                                            <div className="mask_ic">
                                                <img src={item.commodityPictures} alt="图片加载失败" /> 
                                                <div className="Classify">
                                                    <span onClick={() => {
                                                        this.goodsClick(item)
                                                    }} >Buy Now</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                         
                            {/* 每个商品 */}
                            {/* <div className="ipsi_box">
                                <div className="mask_ic">
                                    <img src="https://cdn.shopifycdn.net/s/files/1/0339/7091/3412/files/dimoo_200x200.png?v=1597916246  " alt="图片加载失败" /> 
                                    <div className="Classify">
                                        <span>Buy Now</span>
                                    </div>
                                </div>
                            </div>                             */}
                            
                             
                        </div>   
                        {/* 分类 */}
                        <div className="viewAll_box" onClick={()=>{
                            this.goRouter('/Shop',ipDesigner.classifyId,2)
                        }}>
                           <div className="item">
                                LEARN MORE
                           </div>
                        </div>                     
                        </div>                        
                    </div>
                
            </Content>
        )
    }
}

export default connect(
    // 从store中取state -- 使用prop赋值到组件中
    state => {
        return {
            setting:state.setting
        }
    },
    // 从store中取actions
    {
        updateNav,
    })(Home)