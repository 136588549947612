import React from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop/index';
import history from '../config/history';
import Home from '../pages/Cont/Home';
import Shop from '../pages/Cont/Shop';
import GoodsDetail from '../pages/Cont/GoodsDetail';
import AboutUs from '../pages/Cont/AboutUs';
import OurDesigners from '../pages/Cont/OurDesigners';
import InvestorRelations from '../pages/Cont/InvestorRelations';



const BasicRoute = () => (
    <ScrollToTop>
        <Router history={history}>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/Shop" component={Shop}/>
                <Route exact path="/AboutUs" component={AboutUs}/>
                <Route exact path="/OurDesigners" component={OurDesigners}/>
                <Route exact path="/InvestorRelations" component={InvestorRelations}/>
                <Route path="/GoodsDetail/:commodityId" component={GoodsDetail}/>
            </Switch>
        </Router>

    </ScrollToTop>
);

export default BasicRoute;