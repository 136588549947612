// 导航栏
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Layout,  message } from 'antd';
import {connect} from 'react-redux';
import history from '../config/history';
import {getClassifyList} from '../api/service';
import {updateNav} from '../store/navSetting/action';
// import Logo from '../logo.svg';
import '../config/index';

import "./public.less";

const { Header} = Layout;
class SMHeader extends Component{
    constructor(props){
        super(props);
        this.state = {
            isShowHoverDialog:false,
            initClassifyList:[]
        }
    }
    componentDidMount() {
        this.initRouter()
        this.initClassifyList();

    }
    componentDidUpdate(){
    }
    // 初始化分类列表
    initClassifyList = () => {
        getClassifyList().then((res) => {
            // 设置分类和焦点分类
            this.setState({
                initClassifyList:res,
            })

        })
    }    
    messageInfo = () => {
        message.warning(`Stay tuned for......`);
    };  
    initRouter = () => {
        let wlHash = window.location.hash;
        if(wlHash.indexOf("/Shop") != -1){
            this.props.updateNav({
                navActiveIndex:2
            });
        }else if(wlHash.indexOf("/AboutUs") != -1){
            this.props.updateNav({
                navActiveIndex:3
            });
        }else if(wlHash.indexOf("/OurDesigners") != -1){
            this.props.updateNav({
                navActiveIndex:4
            });
        }else if(wlHash.indexOf("/InvestorRelations") != -1){
            this.props.updateNav({
                navActiveIndex:5
            });
        }
    }
    goRouter = (route,index) => {
        setTimeout(() => {
            // console.log(route);
            // console.log(window.location);
            let wlHash = window.location.hash;
            if(route && wlHash && index){
                if (wlHash.indexOf(route) !== -1) {
                    this.props.updateNav({
                        navActiveIndex:index
                    });
                }else{
                    this.props.updateNav({
                        navActiveIndex:1
                    });
                }
            }
        }, 200);
    };  
    /*
        @param route跳转路由的路径
        @param param跳转路由携带的参数
        @param navActiveIndex路由焦点事件
    */
    goRouterShop = (route,param,navActiveIndex)=>{
        if (!route) return;
        this.props.updateNav({
            navActiveIndex:navActiveIndex
        });
        var path = {
            pathname:route,
            state:param,
        }
        history.push(path)        
    }   
    
    // 滚动到页面底部
    scrollToBottom = () => {
        window.scroll({ top: 20000, left: 0, behavior: 'smooth' });
    }    

    render() {
        let {isShowHoverDialog,initClassifyList} = this.state;
        let {navActiveIndex} = this.props.setting;
        console.log("initClassifyList",initClassifyList);
        return (
                    <Header style={{ display:'flex', zIndex: 1, width: '100%',backgroundColor:'#000',height:'74px',position:'relative' }} >
                        <div className="header-content">
                            <div className="LeftCont">
                                <img src="https://mumodel.oss-cn-shanghai.aliyuncs.com/0000yimo_official/basicprofile.jpeg" alt="pc-logo" className="header-body-logo" />
                                <div key="1" >
                                    <Link to="/" 
                                    className={`routerLink ${navActiveIndex === 1 && 'actived'}`}
                                    onClick={()=>this.goRouter('/Home',1)}
                                >HOME</Link>
                                </div>
                                <div key="2"
                                    onMouseEnter={() => {
                                        this.setState({
                                            isShowHoverDialog:true
                                        })
                                    }}
                                    onMouseLeave={() => {
                                        this.setState({
                                            isShowHoverDialog:false
                                        })
                                    }}
                                >
                                    <Link to="/Shop" 
                                        className={`routerLink ${navActiveIndex === 2 && 'actived'}`}
                                        onClick={()=>this.goRouter('/Shop',2)}>PRODUCTS</Link>
                                </div>
                                <div key="3">
                                    <Link to="/AboutUs" 
                                    className={`routerLink ${navActiveIndex === 3 && 'actived'}`}
                                    onClick={()=>this.goRouter('/AboutUs',3)}
                                    >ABOUT US</Link>
                                </div>                                
                                
                                {/* <div key="4" >
                                    <Link to="/OurDesigners" 
                                    className={`routerLink ${navActiveIndex === 4 && 'actived'}`}
                                    onClick={()=>this.goRouter('/OurDesigners',4)}
                                    >INSTRUCTIONS</Link>
                                </div> */}

                                {/* <div key="5" >
                                    <Link to="/InvestorRelations" 
                                    className={`routerLink ${navActiveIndex === 5 && 'actived'}`}
                                    onClick={()=>this.goRouter('/InvestorRelations',5)}
                                    >INVESTOR RELATIONS</Link>
                                </div> */}

                                <div key="5" >
                                    <span
                                    className={`routerLink`}
                                    onClick={()=>this.scrollToBottom()}
                                    >CONTACT US</span>
                                </div>

                            </div>
                            {/* <div className="RightCont">
                                <img src="https://mumodel.oss-cn-shanghai.aliyuncs.com/0000yimo_official/popmart-logo-black_medium.png" 
                                alt="pc-logo" 
                                onClick={()=>this.messageInfo()}
                                className="rightIcon_bar" />
                                <img src="https://mumodel.oss-cn-shanghai.aliyuncs.com/0000yimo_official/icon-cart-white_medium.png" 
                                alt="pc-logo" 
                                onClick={()=>this.messageInfo()}
                                className="rightIcon_bar" />
                            </div> */}

                        </div>
                        {/* SHOP下拉内容 */}
                        {
                            isShowHoverDialog && 
                            <div className="hoverMsg" 
                                    onMouseEnter={() => {
                                        this.setState({
                                            isShowHoverDialog:true
                                        })
                                    }}
                                    onMouseLeave={() => {
                                        this.setState({
                                            isShowHoverDialog:false
                                        })
                                    }}
                                >
                                <div className="hoverContBox">
                                    {
                                        initClassifyList.map((item) => {
                                            return (
                                                    <div className="item" 
                                                        onClick={()=>this.goRouterShop('/Shop',item.id,2)}
                                                        key={item.cname}
                                                    >{item.cname}</div>
                                            )
                                        })
                                    }
                                </div>                                               
                            </div>
                        }
                    </Header>
        )
    }
}

export default connect(
    // 从store中取state -- 使用prop赋值到组件中
    state => {
        // console.log(state);
        return {
            setting:state.setting
        }
    },
    // 从store中取actions
    {
        updateNav,
        
    })(SMHeader)