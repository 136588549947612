import axios from "axios";
import {
    Modal
} from "antd";

//创建一个axios示例
const service = axios.create({
    baseURL: process.env.REACT_APP_URL || "", // api 的 base_url
    timeout: 10000, // request timeout
});


// 请求拦截器
service.interceptors.request.use(
    (config) => {
        // 让每个请求携带token-- ['authorization']为自定义key 请根据实际情况自行修改
        // config.headers.token = "token";
        // console.log(config)
        return config;
    },
    (error) => {
        // Do something with request error
        console.log(error); // for debug
        Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    (response) => {
        let {
            data,
            config
        } = response;
        let {
            FullCatch,//是否返回完整异常信息
            FullResponse//是否返回完整成功信息
        } = config.headers;
        if (data.errorCode === "0007") {
           console.log("error 0007");
           Modal.confirm({
            title: "提示",
            content: data.msg || "系统错误-status500",
        });           
        } else if (data.errorCode === "0000") {
            return data.data
        } else {
            if (FullResponse) {
                return data
            }
            return Promise.reject(FullCatch ? data : data ? data.errorMsg : '服务器响应错误', data)
        }

    },
    (error) => {
        console.log("响应异常：", error)
        return Promise.reject(error.toString());
    }
);

export default service;