// 导航栏
import React, { Component } from "react";
import { Layout, Form, Input, Button, message } from 'antd';
import { connect } from 'react-redux';
import history from '../config/history';
import {pcPostMessage} from '../api/service';
import {throttle} from '../config/util';
import { updateNav } from '../store/navSetting/action';

import "./public.less";

const { Footer } = Layout;

class SMHeader extends Component {
    constructor() {
        super();
        this.state = {
            isShowHoverDialog: false,
        }
        this.formRef = React.createRef();
    }
    componentDidMount() {
    }
    componentDidUpdate() {
    }
    // 提交请求
    handleSubmit = throttle((values) => {
        console.log('Success:', values);
        pcPostMessage(values).then(() => {
            message.success("Message successfully");
            this.formRef.current.setFieldsValue({
                name: '',
                email: '',
                phone: '',
                content:''
            });            
        }).catch(() => {
            message.error("System error, message failure");
        })
    },1000)

    /*
        @param route跳转路由的路径
        @param param跳转路由携带的参数
        @param navActiveIndex路由焦点事件
    */
        goRouterShop = (route,param,navActiveIndex)=>{
            if (!route) return;
            this.props.updateNav({
                navActiveIndex:navActiveIndex
            });
            var path = {
                pathname:route,
                state:param,
            }
            history.push(path)        
        }    

    // 滚动到页面底部
    scrollToBottom = () => {
        window.scrollTo(0,document.documentElement.clientHeight); 
    }

    // 跳转页面
    navRouterTo = () => {
        window.location.href = "https://beian.miit.gov.cn/#/Integrated/index";
    }

    render() {
        return (
            <Footer style={{ width: '100%', backgroundColor: '#000', color: '#fff' }}>
                <div className="footerBox">
                    {/* 联系我们提交 */}
                    <div className="ConcatUs_box">
                        <div className="title">ENTER YOUR EMAIL TO STAY IN TOUCH!</div>
                        <div className="formContYM">
                            <Form
                                ref={this.formRef} 
                                name="contact"
                                onFinish={this.handleSubmit}
                            >
                                <Form.Item
                                    name="name"
                                >
                                    <Input placeholder="Please input name" />
                                </Form.Item>


                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            type: 'email',
                                            required: true,
                                            message: 'Please input your Email!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Email" />
                                </Form.Item>

                                <Form.Item
                                    name="phone"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'Please input your PhoneNumber!',
                                    //     },
                                    // ]}
                                >
                                    <Input placeholder="Please input Phone Number" />
                                </Form.Item>

                                <Form.Item
                                    name="content"
                                >
                                    <Input.TextArea  placeholder="Leave a message" />
                                </Form.Item>

                                <Form.Item
                                >
                                    <Button type="default" htmlType="submit" className="initSumb">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                    {/* 菜单 */}
                    <div className="menu_box">
                        <div>
                            <div className="title">INFORMATIONS</div>
                            <div onClick={()=>this.goRouterShop('/',"",1)}>HOME</div>
                            <div onClick={()=>this.goRouterShop('/Shop',"",2)}>PRODUCTS</div>
                            <div onClick={()=>this.goRouterShop('/AboutUs',"",3)}> ABOUT US</div>
                            {/* <div onClick={()=>this.goRouterShop('/OurDesigners',"",4)}>INSTRUCTIONS</div> */}
                            {/* <div onClick={()=>this.goRouterShop('/InvestorRelations',"",5)}>CONTACT US</div> */}
                            <div onClick={()=>this.scrollToBottom()}>CONTACT US</div>
                        </div>
                        <div>
                            <div className="title">FOLLOW US</div>
                            <div>
                                <a href="https://www.instagram.com/mu.artmodel/" target="_blank">
                                    <img style={{width:"46px",height:"46px"}}  src="https://mumodel.oss-cn-shanghai.aliyuncs.com/0000yimo_official/aboutus/instagram-fil.png" alt="ins" />
                                </a>
                            </div>
                            <div>
                                <a href="https://weibo.com/mumodel" target="_blank">
                                    <img style={{width:"46px",height:"46px"}} src="https://mumodel.oss-cn-shanghai.aliyuncs.com/0000yimo_official/aboutus/weibo.png" alt="weibo" />
                                </a>
                            </div>
                            <div>
                                <img style={{width:"46px",height:"46px"}} src="https://mumodel.oss-cn-shanghai.aliyuncs.com/0000yimo_official/aboutus/yimo-weixin.jpeg" alt="图片加载失败" />
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="tdCont">
                    <div className="cont">
                        <div><b>Shanghai Art Model Electronic & Technology Co.,Ltd</b></div>
                        <div><b>Add:</b> No.5917,Chuanzhou Rd,Pudong New Area,201205,Shanghai,China</div>
                        <div><b>Email:</b>  gina.chen@mumodel.com mu@mumodel.com</div>
                        <div><b>T:</b> 86-21-58942999 86-21-58946507</div>
                    </div>
                </div>
                <div onClick={this.navRouterTo} style={{cursor:"pointer"}}>
                    Copyright © 2000-2015 艺模电子科技 沪ICP备17038033号-2 All Rights Reserved
                </div>
            </Footer>
        )
    }
}

export default connect(
    // 从store中取state -- 使用prop赋值到组件中
    state => {
        // console.log(state);
        return {
            setting: state.setting
        }
    },
    // 从store中取actions
    {
        updateNav,

    })(SMHeader)