// 关于我们
import React, { Component } from "react";
import { Layout} from 'antd';

import "./css/otherPage.less";

const { Content } = Layout;

class AboutUs extends Component{
    constructor(){
        super();
        this.state = {
        }
    }
    componentDidMount() {
    }

    getmodeImgDivItem =(length = 17)=>{
        var arrImg = [];
        for (let index = 1; index < length; index++) {
            arrImg.push(index);
        }
        return arrImg.map((index) => {
            return (
                <img key={index} className="modeImgDivItem" src={`https://mumodel.oss-cn-shanghai.aliyuncs.com/0000yimo_official/pp_logo_2/${index}.png`} alt="" />
            )   
        })
                  
    }

    render() {
        return (
            <Content>
                {/* 头部 */}
                <div className="obanner_box">

                </div>
                {/* Introduction */}
                <div className="idn_box">
                    <div className="idnb_cont">
                        <div className="title">Introduction</div>
                        <div>About MU</div>
                        <p>Shanghai Art Model Electronic & Technology Co.,Ltd and its newly brand MU are enjoying high reparation and market share in Chinese 3D metal puzzle industries. The company is an Innovative enterprise integrating R&D, design, production and marketing and its history can be traced back to 2000.</p>

                        <p>Thanks to its intelligent and strong R&D team and solid manufacturer& management background, our mother company owns a lot national patents and even pass the CSR(Sedex) and ISO 9001 audit. </p>

                        <p>2017 is a milestone event year for Shanghai Art Model Co., Ltd,who established the business cooperation with Hasbro and became the only one manufacturer that get the authorization from Hasbro for 3D Transformer metal puzzle production in China.</p>

                        <p>Meanwhile, hobby collector and fans can get more choice from our products range due to our official cooperation with the famous cartoon and anime companies, such as “The Legend of Hei” with Beijing Hanmuchunhua,“Qin Dynasty” with Hanzhou Xuanji, “Son Goku” with Beijing Oct Culture, “Badeer” with Shanghai Xinyu and so on. </p>

                        <p>With the rapid and healthy growth, the military series products cooperated with CSIC(China Shipbuilding Industry Cooperation) and CASC (China Aerospace Science & Technology Cooperation) spur us to reach a whole new level and an unprecedented height!</p>

                        <p>With exquisite product details and quality, Shanghai Art Model is one of the the leading metal model/puzzle kits manufacturer and its products are warmly welcomed by customer in China, America, Europe,Japan, Korea,and South Asia. MU also already becomes a symbol of “exquisite design”!</p>

                        <p>“Success on hand and craft in heart”is our enterprise motto. Go with us for assembling joy of life!</p>
                    </div>
                </div>

                {/* HISTORY */}
                <div className="hy_box">
                    <div className="hyMark_cont">

                    </div>
                    <div className="hyb_cont">
                        <div className="section-title">HISTORY</div>
                        <div className="section-body">
                            <div className="section-item">
                                {/* 底部样式1 */}
                                <div className="poster">
                                    <div className="poster-avatar" style={{backgroundColor:"#d33339"}}>
                                        <img src="https://mumodel.oss-cn-shanghai.aliyuncs.com/0000yimo_official/aboutus/1.png" alt="" />
                                    </div>
                                    <div className="poster-title" style={{color:"#d33339"}}>
                                        2017
                                    </div>
                                </div>
                                {/* 弹出样式 */}
                                <div className="pop" >

                                    <div className="row row-full">
                                        <div className="pop-avatar col col-xs">
                                            <img src="https://mumodel.oss-cn-shanghai.aliyuncs.com/0000yimo_official/aboutus/1.png" alt="" />
                                        </div>
                                        <div className="pop-summary col col-xs">
                                                <div className="anima">
                                                    <p><strong>2017</strong></p>
                                                    <p><em>MU(Art Model) Cooperated with Hasbros for Transformer Series.</em></p>
                                                    <p></p>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="pop-mask"></div>

                                </div>
                            </div>
                            <div className="section-item">
                                {/* 底部样式2 */}
                                <div className="poster">
                                    <div className="poster-avatar" style={{backgroundColor:"#cf86f2"}}>
                                        <img src="https://mumodel.oss-cn-shanghai.aliyuncs.com/0000yimo_official/aboutus/2.png" alt="" />
                                    </div>
                                    <div className="poster-title" style={{color:"#cf86f2"}}>
                                        2018
                                    </div>
                                </div>
                                {/* 弹出样式 */}
                                <div className="pop">

                                    <div className="row row-full">
                                        <div className="pop-avatar col col-xs">
                                            <img src="https://mumodel.oss-cn-shanghai.aliyuncs.com/0000yimo_official/aboutus/2.png" alt="" />
                                        </div>
                                        <div className="pop-summary col col-xs">
                                                <div className="anima">
                                                    <p><strong>2018</strong></p>
                                                    <p><em>MU'market share increased triply because of the China Town series.</em></p>
                                                    <p></p>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="pop-mask"></div>

                                </div>
                            </div>
                            <div className="section-item">
                                {/* 底部样式3 */}
                                <div className="poster">
                                    <div className="poster-avatar" style={{backgroundColor:"#ff9500"}}>
                                        <img src="https://mumodel.oss-cn-shanghai.aliyuncs.com/0000yimo_official/aboutus/3.png" alt="" />
                                    </div>
                                    <div className="poster-title" style={{color:"#ff9500"}}>
                                        2019
                                    </div>
                                </div>
                                {/* 弹出样式 */}
                                <div className="pop">

                                    <div className="row row-full">
                                        <div className="pop-avatar col col-xs">
                                            <img src="https://mumodel.oss-cn-shanghai.aliyuncs.com/0000yimo_official/aboutus/3.png" alt="" />
                                        </div>
                                        <div className="pop-summary col col-xs">
                                                <div className="anima">
                                                    <p><strong>2019</strong></p>
                                                    <p><em>The Moon Palace came to be the best seller till today and MU sales continued to soar rapidly</em></p>
                                                    <p></p>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="pop-mask"></div>

                                </div>
                            </div>
                            <div className="section-item">
                                {/* 底部样式4 */}
                                <div className="poster">
                                    <div className="poster-avatar" style={{backgroundColor:"#ccdaf7"}}>
                                        <img src="https://mumodel.oss-cn-shanghai.aliyuncs.com/0000yimo_official/aboutus/4.png" alt="" />
                                    </div>
                                    <div className="poster-title" style={{color:"#ccdaf7"}}>
                                        2021
                                    </div>
                                </div>
                                {/* 弹出样式 */}
                                <div className="pop vleft">

                                        <div className="row row-full">
                                            <div className="pop-avatar col col-xs">
                                                <img src="https://mumodel.oss-cn-shanghai.aliyuncs.com/0000yimo_official/aboutus/4.png" alt="" />
                                            </div>
                                            <div className="pop-summary col col-xs">
                                                    <div className="anima">
                                                        <p><strong>2021</strong></p>
                                                        <p><em>MU military series cooperated with CSIC and CASC crowdfuned RMB 2,260,215 in a month on Bilibili.com,which also created a new high record in Metal puzzle industry.</em></p>
                                                        <p></p>
                                                    </div>
                                            </div>
                                        </div>
                                        <div className="pop-mask"></div>

                                </div>
                            </div>
                            
                            {/* 底部样式5 */}
                            {/* <div className="section-item">
                                <div className="poster">
                                    <div className="poster-avatar" style={{backgroundColor:"#f3e6c2"}}>
                                        <img src="https://cdn.shopifycdn.net/s/files/1/0339/7091/3412/files/panshen_a_400x.png?v=1593970098" alt="" />
                                    </div>
                                    <div className="poster-title" style={{color:"#f3e6c2"}}>
                                        2018
                                    </div>
                                </div>
                                <div className="pop vleft">

                                    <div className="row row-full">
                                        <div className="pop-avatar col col-xs">
                                            <img src="https://cdn.shopifycdn.net/s/files/1/0339/7091/3412/files/panshen_a_400x.png?v=1593970098" alt="" />
                                        </div>
                                        <div className="pop-summary col col-xs">
                                                <div className="anima">
                                                    <p><strong>2018.11</strong></p>
                                                    <p><em>YI MO was officially formed and opened its first store in EC Mall, Beijing.</em></p>
                                                    <p></p>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="pop-mask"></div>                                    
                                </div>
                            </div> */}

                            {/* 底部样式6 */}
                            {/* <div className="section-item vleft">
                                <div className="poster">
                                    <div className="poster-avatar" style={{backgroundColor:"#ffffff"}}>
                                        <img src="https://cdn.shopifycdn.net/s/files/1/0339/7091/3412/files/bobo_a_400x.png?v=1593970132" alt="" />
                                    </div>
                                    <div className="poster-title" style={{color:"#ffffff"}}>
                                        2019
                                    </div>
                                </div>
                                <div className="pop vleft">

                                    <div className="row row-full">
                                        <div className="pop-avatar col col-xs">
                                            <img src="https://cdn.shopifycdn.net/s/files/1/0339/7091/3412/files/bobo_a_400x.png?v=1593970132" alt="" />
                                        </div>
                                        <div className="pop-summary col col-xs">
                                                <div className="anima">
                                                    <p><strong>2019.11</strong></p>
                                                    <p><em>YI MO was officially formed and opened its first store in EC Mall, Beijing.</em></p>
                                                    <p></p>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="pop-mask"></div>                                     

                                </div>
                            </div>                                                                                     */}

                        </div>
                    </div>
                </div>

               {/* Meet the Team */}
               {/* <div className="idn_box">
                    <div className="idnb_cont">
                        <div className="title">Meet the Team</div>
                        <div>Team Introduction</div>
                        <p>The management team of YI MO is highly experienced in retail distribution and intellectual property development. Many of them hold MBA degrees from renowned institutions such as Peking University. They introduce state-of-the-art management concepts into their daily operations. Currently, YI MO has offices in major Chinese cities, including Beijing, Shanghai, Nanjing, and Dongguan exceeding 1500 employees who take charge of the physical stores, product design, intellectual property licenses, app development, and exhibition management.</p>
                        <img className="modeImg" src="https://cdn.shopifycdn.net/s/files/1/0339/7091/3412/files/about-team_1920x500.png?v=1594290349" alt="" />
                    </div>
                </div> */}

               {/* Partnership */}
               <div className="idn_box">
                    <div className="idnb_cont">
                        <div className="title">Partnership</div>
                        <div>Partnership Brands</div>
                        {/* <img className="modeImg PsImg" src="https://cdn.shopifycdn.net/s/files/1/0339/7091/3412/files/about_banner4_1_1244x650.png?v=1593794719" alt="" /> */}
                        <div  className="modeImgDiv">
                            {
                                this.getmodeImgDivItem(16)
                            }
                        </div>
                    </div>
                </div>                

            </Content>
        )
    }
}

export default AboutUs;