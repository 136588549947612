import * as navType from './action-type';

let defaultState = {
      navActiveIndex:1,//默认显示导航栏焦点
}
export const setting = (state = defaultState, action) => {
    switch (action.type) {
      case navType.SETNAV:
        console.log(action)
        return {...action.setting}
      default:
        return state
    }
  }

