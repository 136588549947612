import {createStore,applyMiddleware,combineReducers} from 'redux';
import * as user from './navSetting/reducer';
// import thunk from 'redux-thunk';

let reducers = combineReducers({
    ...user,
})

let store = createStore(
        reducers,
        // applyMiddleware(thunk)
    )

export default store;

