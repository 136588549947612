// INVESTOR RELATIONS - 投资者关系
import React, { Component } from "react";
import { Layout} from 'antd';

import "./css/otherPage.less";

const { Content } = Layout;

class InvestorRelations extends Component{
    constructor(){
        super();
        this.state = {
            selectIndex:0,//默认选中索引第一个
            getData:[], //选中数据
            cparcosItem:[
                // 0
                [
                    {
                        title:"CORPORATE GOVERNANCE",
                        item:[
                            {
                                li_title:"BOARD OF DIRECTORS",
                                libody_list_cont:"LIST OF DIRECTORS AND THEIR ROLES AND FUNCTIONS",
                                times:"",
                                pdfUrl:"https://cdn.shopifycdn.net/s/files/1/0339/7091/3412/files/LIST_OF_DIRECTORS_AND_THEIR_ROLES_AND_FUNCTIONS_46e517bb-99fa-458f-abb4-a5f19b0ba503.pdf?v=1608617897",
                            },
                            {
                                li_title:"BOARD OF DIRECTORS",
                                libody_list_cont:"TERMS OF REFERENCE FOR THE AUDIT COMMITTEE",
                                times:"",
                                pdfUrl:"https://cdn.shopifycdn.net/s/files/1/0339/7091/3412/files/LIST_OF_DIRECTORS_AND_THEIR_ROLES_AND_FUNCTIONS_46e517bb-99fa-458f-abb4-a5f19b0ba503.pdf?v=1608617897",

                            },
                            {
                                li_title:"TERMS OF REFERENCE FOR THE NOMINATION COMMITTEE",
                                libody_list_cont:"TERMS OF REFERENCE FOR THE AUDIT COMMITTEE",
                                times:""
                            },
                        ]
                    }
                ],
                // 1
                [
                    {
                    title:"PROSPECTUS",
                    item:[
                        {
                            li_title:"",
                            libody_list_cont:"Prospectus",
                            times:"2020-12-01"
                        }
                    ]
                    }
                ],
                // 2
                [
                    {
                    title:"ANNOUNCEMENTS AND CIRCULARS",
                    item:[
                        {
                            li_title:"",
                            libody_list_cont:"Notification Letter and Request Form to Non-registered Holders",
                            times:"2020-12-01"
                        }
                    ]
                    }
                ],
                // 3
                [
                    {
                    title:"REPORTS AND REPORTS",
                    item:[
                        {
                            li_title:"",
                            libody_list_cont:"ANNUAL_REPORT_2020",
                            times:"2020-12-01"
                        }
                    ]
                    }
                ],
                // 4
                [
                    {
                    title:"INVERSTORS CONTACT",
                    item:[
                        {
                            li_title:"BOARD OF DIRECTORS",
                            libody_list_cont:"ir@YIMO.com",
                            times:"2020-12-01"
                        }
                    ]
                    }
                ],
                // 5
                [
                    {
                        title:"Others",
                        item:[]
                    }
                ]
            ]
        }
    }
    componentDidMount() {
        this.initData(0);
    }

    selectIR = (params = 0) => {
        this.setState({
            selectIndex:params
        },() => {
            this.initData(params);
        })
    }

    initData = (index = 0) => {
        this.setState({
            getData:this.state.cparcosItem[index]
        })
    }

    render() {
        let {selectIndex,getData} = this.state;
        return (
            <Content>
               {/* 头部 */}
               <div className="obanner_box ir_box">
                   <div className="max_box">
                        <div className="title">YIMO MART</div>
                        <div className="title">INVESTOR RELATIONS</div>
                    </div>
                </div>

                {/* INVESTOR RELATIONS */}
                <div className="irs_box">
                    <div className="irsb_cont">
                        <div className="title">INVESTOR RELATIONS</div>
                        <div className="cparco_box">
                            <div className={`cparco_item ${selectIndex == 0 && 'current'}`} onClick={()=>{
                                this.selectIR(0)
                            }}>
                                Corporate Governance
                            </div>
                            <div className={`cparco_item ${selectIndex == 1 && 'current'}`} onClick={()=>{
                                this.selectIR(1)
                            }}>
                                Prospectus 
                            </div>
                            <div className={`cparco_item ${selectIndex == 2 && 'current'}`} onClick={()=>{
                                this.selectIR(2)
                            }}>
                                Announcements and Circulars
                            </div>
                            <div className={`cparco_item ${selectIndex == 3 && 'current'}`} onClick={()=>{
                                this.selectIR(3)
                            }}>
                                Result and Reports
                            </div>  
                            <div className={`cparco_item ${selectIndex == 4 && 'current'}`} onClick={()=>{
                                this.selectIR(4)
                            }}>
                                Contact us
                            </div>  
                            <div className={`cparco_item ${selectIndex == 5 && 'current'}`} onClick={()=>{
                                this.selectIR(5)
                            }}>
                                Others
                            </div>                                                                                                                                            
                        </div>
                    </div>
                </div>

                {/* cparcos_cont */}
                <div className="irs_box cparcos_box">
                    <div className="irsb_cont">
                        <div className="title">{getData.length && getData[0].title}</div>
                        {/* 每一个PDF */}
                        {
                            getData.length && getData[0].item.map((item,index)=>{
                                return (
                                    <div className="list-item" key={index + 1}>
                                        <div className="li_title">{item.li_title}</div>
                                            {
                                            item.pdfUrl ?
                                            <a href={item.pdfUrl} className="li_body" key={index + 1} alt={item.libody_list_cont}>
                                                <div className="libody_PDF">PDF</div>
                                                <div className="libody_list">
                                                    <div>{item.libody_list_cont}</div>
                                                    <div className="times">{item.times}</div>
                                                </div>
                                            </a>
                                            :
                                            <div className="li_body" key={index + 1}>
                                                <div className="libody_PDF">PDF</div>
                                                <div className="libody_list">
                                                    <div>{item.libody_list_cont}</div>
                                                    <div className="times">{item.times}</div>
                                                </div>
                                            </div>
                                            }
                                    </div> 
                                )
                            })
                        }

                    </div>
                </div>


            </Content>
        )
    }
}

export default InvestorRelations;