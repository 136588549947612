import request from "./config/request";
import {
  roetationList,
  classifyList,
  classifyCommodity,
  allCommodities,
  commodityList,
  postMessage,
  commodity,
} from "./config/api";


/**
 * 获取轮播图
 * @param {*} params xxx
 * @returns
 */
export function getRoetationList(params = {}) {
  return request({
    url: roetationList,
    method: "get",
    params,
  });
}

/**
 * 获取所有分类
 * @param {*} params xxx
 * @returns
 */
 export function getClassifyList(params = {}) {
  return request({
    url: classifyList,
    method: "get",
    params,
  });
}

/**
 * 根据分类获取商品
 * @param {*} classifyId 分类id
 * @returns
 */
 export function getClassifyCommodity(params = {}) {
  return request({
    url: classifyCommodity,
    method: "get",
    params,
  });
}

/**
 * 根据分类获取商品
 * @param {*} commodityId 商品id
 * @returns
 */
 export function getCommodityDetail(params = {}) {
  return request({
    url: commodity,
    method: "get",
    params,
  });
}

/**
 * 首页查询系统商品
 * @returns
 */
 export function getSysCommodities(params = {}) {
  return request({
    url: allCommodities,
    method: "get",
    params,
  });
}

/**
 * 查询商品列表
 * @param {*} classifyType 1:系统分类 2:自定义分类
 * @returns
 */
 export function getCommodityList(params = {}) {
  return request({
    url: commodityList,
    method: "get",
    data:params,
  });
}

/**
 * 添加留言
 * @param {*} message  email phone content
 * @returns
 */
 export function pcPostMessage(message = {}) {
  return request({
    url: postMessage,
    method: "post",
    data:message
  });
}