// ip和设计
import React, { Component } from "react";
import { Layout} from 'antd';

import "./css/otherPage.less";

const { Content } = Layout;

class OurDesigners extends Component{
    constructor(){
        super();
        this.state = {
        }
    }
    componentDidMount() {
    }

    render() {
        return (
            <Content>
               {/* 头部 */}
               <div className="obanner_box od_box">
                   <div className="max_box">
                        <div className="title">OUR IP & Designers</div>
                        <div>
                        The designer toy industry, which emerged two decades ago, has boomed in China in recent years, breaking the ancient stereotype of “toys are for kids.” Designer toys integrate diverse concepts such as art, design, painting, fashion, and modern sculpture into figures. With unique artistic designs, these toys depict various characters and convey different lifestyles. They generate a “love at first sight” reaction, and fans are crazy about them, currently one of the most trendy pop culture items on the market.
                        </div>
                    </div>
                </div>  

                {/* Designers */}
                <div className="deg_box">
                    {/* 每个设计师 */}
                    <div className="degb_cont">
                        <div className="author-image">
                            <img src="https://cdn.shopifycdn.net/s/files/1/0339/7091/3412/files/Image_1_2x_7d39d6e4-c111-4252-86df-250d634cefd4_420x.png?v=1596099242" />
                        </div>
                        <div className="brand-info-block">
                            <div className="brand-image pc">
                                <span>
                                    <img src="https://cdn.shopifycdn.net/s/files/1/0339/7091/3412/files/Image_1_2x_724640bf-b002-4492-8494-5b1a37e323f6_180x.png?v=1596099259" />
                                </span>
                            </div>
                            <div className="brand_box">
                                <div className="brand-title designer-li-left">
                                    <div className="brand-name">
                                        <span>Molly</span>
                                    </div>
                                    <div className="brand-author">
                                        By
                                        <span> Kenny Wang</span>
                                    </div>
                                </div>
                                <div className="brand-desc designer-li-left designer-desc-box innerbox">
                                    <p></p><p>Molly figurine, by Kenny Wong, was originally inspired by a young child. She has emerald eyes, a pouting mouth, and cute temperament. The first time Kenny met Molly, she was with a beret hat, an artist apron, a color palette, and brush in her hands. That's how the little figurine artist was born. She is stubborn, adorable, smart, proud, and fun. With such a lovely personality, along with various designs and outfits, she has won the hearts of numerous fans that have made Molly one of the most famous pop culture toys.</p><p></p>
                                </div>
                                <div className="brand-url  designer-li-left pc">
                                    <div>
                                        <span>Buy Molly</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 每个设计师 */}
                    <div className="degb_cont">
                        <div className="author-image">
                            <img src="https://cdn.shopifycdn.net/s/files/1/0339/7091/3412/files/Image_1_2x_615b88ee-82be-41b3-a38a-8bebea319abd_420x.png?v=1596099331" />
                        </div>
                        <div className="brand-info-block">
                            <div className="brand-image pc">
                                <span>
                                    <img src="https://cdn.shopifycdn.net/s/files/1/0339/7091/3412/files/labubu_2x_05fa68ab-8c6d-4a20-8c8d-9183f32d2621_180x.png?v=1596099344" />
                                </span>
                            </div>
                            <div className="brand_box">
                                <div className="brand-title designer-li-left">
                                    <div className="brand-name">
                                        <span>Molly</span>
                                    </div>
                                    <div className="brand-author">
                                        By
                                        <span> Kasing Lung</span>
                                    </div>
                                </div>
                                <div className="brand-desc designer-li-left designer-desc-box innerbox">
                                    <p></p><p>Kasing Lung is the first Chinese winner of the Illustration Award in Belgium. In 2010, he transitioned from a professional illustrator to a toy designer. Partnering with How2work, a renowned toy brand in Hong Kong, China, they released an art toy series. Now Mr. Lung brings fantasy characters of his drawings into the 3D world, such as The Monsters series with characters like Labubu and Tycoco; he has truly impressed consumers.</p><p></p>
                                </div>
                                <div className="brand-url  designer-li-left pc">
                                    <div>
                                        <span>Buy Molly</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

{/* 每个设计师 */}
<div className="degb_cont">
                        <div className="author-image">
                            <img src="https://cdn.shopifycdn.net/s/files/1/0339/7091/3412/files/pucky_5e3e2606-4692-4118-b9a7-4bccb943cfd5_420x.png?v=1600677009" />
                        </div>
                        <div className="brand-info-block">
                            <div className="brand-image pc">
                                <span>
                                    <img src="https://cdn.shopifycdn.net/s/files/1/0339/7091/3412/files/puckyip_180x.png?v=1600679745" />
                                </span>
                            </div>
                            <div className="brand_box">
                                <div className="brand-title designer-li-left">
                                    <div className="brand-name">
                                        <span>Molly</span>
                                    </div>
                                    <div className="brand-author">
                                        By
                                        <span> Ayan</span>
                                    </div>
                                </div>
                                <div className="brand-desc designer-li-left designer-desc-box innerbox">
                                    <p></p>
                                    <p>The DIMOO WORLD series is filled with the beauty of fantasy. The story is about the journey of a little boy named Dimoo. In the real world, he is lost</p>
                                    <p></p>
                                </div>
                                <div className="brand-url  designer-li-left pc">
                                    <div>
                                        <span>Buy Molly</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    

                </div>                              
            </Content>
        )
    }
}

export default OurDesigners;