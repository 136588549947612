
// let proxyAff = "/pc"

// console.log("process.env.REACT_APP_URL",process.env.REACT_APP_URL)

// module.exports = {
//     TESTGET:"测试get请求api",
//     roetationList:proxyAff+"/roetation/list", //轮播图查看所有
//     classifyList:proxyAff+"/classify/list", //查询所有分类
//     classifyCommodity:proxyAff+"/commodity/classifyCommodity", //根据分类id查商品
//     allCommodities:proxyAff+"/commodity/allCommodities", //商城首页查询商品
//     commodityList:proxyAff+"/commodity/list", //查商品列表
//     postMessage:proxyAff+"/message", //添加留言
    
// }

module.exports = {
    TESTGET:"测试get请求api",
    roetationList:"/roetation/list", //轮播图查看所有
    classifyList:"/classify/list", //查询所有分类
    classifyCommodity:"/commodity/classifyCommodity", //根据分类id查商品
    allCommodities:"/commodity/allCommodities", //商城首页查询商品
    commodityList:"/commodity/list", //查商品列表
    postMessage:"/message", //添加留言
    commodity:"/commodity", //查询单个商品
    
}