// 商品详情
import React, { Component } from "react";
import { Layout,Button,message} from 'antd';

import PhotoLoupe from '../../components/PhotoLoupe';
import {getCommodityDetail} from '../../api/service';
import "./css/Shop.less";

const { Content } = Layout;

class GoodsDetail extends Component{
    constructor(){
        super();
        this.state = {
            commodityDetail:{}, //分类列表
            PhotoLoupeUrl:"",
        }
    }
    componentDidMount() {
        // console.log("获取路由传递参数",this.props.match.params)
        if(this.props.match.params.commodityId){
            this.initCommodityDetail(this.props.match.params.commodityId);
        }else{
            this.props.history.goBack();
        }
    }

    // 初始化分类列表
    initCommodityDetail = (commodityId) => {
        getCommodityDetail({commodityId:commodityId}).then((res) => {
            if(res.commodityImages){
                try {
                    res.commodityImages = JSON.parse(res.commodityImages);
                } catch (error) {
                    res.commodityImages = [];
                }
            }else{
                res.commodityImages = [];
            }
            // 设置分类和焦点分类
            // console.log(res.commodityImages);
            this.setState({
                commodityDetail:res,
                PhotoLoupeUrl:res.commodityImages[0] && res.commodityImages[0].url
            })
        })        
    }

    //鼠标进入
    imgMouseEnter = (url) => {
        url && this.setState({
            PhotoLoupeUrl:url
        })
    }

    //去购买
    toBuy = () => {
        message.warning(`Stay tuned for......`);
    }    

    render() {
        let {commodityDetail,PhotoLoupeUrl} = this.state;
        // console.log("commodityDetail.commodityImages",commodityDetail.commodityImages);
        return (
            <Content >
                <div className="Shop_Box">
                    {/* 头部标题 */}
                    <div className="Shop_titleBox">
                        <div className="title">
                            <span>PRODUCTS  |  </span>
                            <span className="actived">  {commodityDetail.commodityName}</span>
                        </div>
                    </div>

                    {/* 商品详情图片 */}
                    <div className="Shop_ListBox goods_DetailBox">
                        {/* 商品详情图片 */}
                        <div className="Detail_box">
                            <PhotoLoupe className="PhotoLoupeCont" imgUrl={PhotoLoupeUrl} />
                            <div className="gdList">
                                <div>
                                    {
                                        commodityDetail.commodityImages && commodityDetail.commodityImages.map((item,index) => {
                                            return (
                                                <img src={item.url} key={item.url}
                                                     onMouseEnter={
                                                         () => {
                                                             this.imgMouseEnter(item.url)
                                                         }
                                                     }
                                                 />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                        {/* 商品详情信息 */}
                        <div className="Detail_msg">
                            <div className="dm_name">{commodityDetail.commodityName}</div>
                            {commodityDetail.commodityPrice ? 
                                <div className="dm_price">${commodityDetail.commodityPrice}</div>
                                :
                                ""
                            }
                            <div className="det_Inf_title">Detail Information</div>
                            <div className="det_Inf_content">
                                <pre>
                                    {commodityDetail.commodityContent}
                                </pre>
                            </div>
                            <Button type="default" className="initSumb" className="initSumb" onClick={
                                () => {
                                    this.toBuy()
                                }
                            }>
                                To Buy
                            </Button>
                        </div>

                    </div>
               
                    {/* 商品详情图片竖排列 */}
                    {
                    commodityDetail.palceImg &&
                        <div className="clo_goodsDetailImg">
                            <div className="clg_title">SHOW</div>
                            <div className="clg_img">
                                <img src={commodityDetail.palceImg} key={commodityDetail.palceImg} />
                            </div>
                        </div>
                    }                    
                    {/* <div className="clo_goodsDetailImg">
                        <div className="clg_title">SHOW</div>
                        <div className="clg_img">
                            {
                                commodityDetail.commodityImages && commodityDetail.commodityImages.map((item,index) => {
                                    return (
                                        <img src={item.url} key={item.url} />
                                    )
                                })
                            }
                        </div>
                    </div> */}
               
               </div>
            </Content>
        )
    }
}

export default GoodsDetail;